var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"form",staticClass:"company-ruleForm",attrs:{"model":_vm.form,"label-width":"100px","label-position":"top"}},[_c('el-alert',{attrs:{"closable":false,"title":"Tips：输入完成后敲击回车可直接新增一行","type":"info"}}),_vm._l((_vm.form.items),function(item,index){return _c('el-row',{key:item.key,attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"跟进状态名称","prop":'items.' + index + '.name',"rules":[
          { required: true, message: '请输入跟进状态名称', trigger: 'blur' },
        ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addRow(`input${index + 1}`)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"所属层级","prop":'items.' + index + '.parentId'}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","clearable":"","filterable":""},model:{value:(item.parentId),callback:function ($$v) {_vm.$set(item, "parentId", $$v)},expression:"item.parentId"}},_vm._l((_vm.parentOptions),function(option){return _c('el-option',{key:option.id+index,attrs:{"label":option.name,"value":option.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"次序","prop":'items.' + index + '.seq',"rules":[
          {
            required: true,
            message: '请输入次序',
            trigger: ['blur', 'change'],
          },
        ]}},[_c('el-input-number',{ref:`inputNumber${index}`,refInFor:true,attrs:{"label":"请输入","min":1},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addRow(`inputNumber${index + 1}`)}},model:{value:(item.seq),callback:function ($$v) {_vm.$set(item, "seq", $$v)},expression:"item.seq"}})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"操作","prop":"userCountLimit"}},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_vm._v("删除")])],1)],1)],1)}),_c('el-form-item',{staticStyle:{"text-align":"right","margin-top":"30px"}},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.onCancel}},[_vm._v("关 闭")]),_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.addRow}},[_vm._v("新增一行")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("提交创建")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }